// Usage: import '@core/date-extensions';
// Description: Add a method to Date object to return ISO string with timezone

export {}; // This is a module (ESM)

declare global {
  interface Date {
    toISOStringWithTimeZone(): string;
  }
}

// Pad a number to 2 digits
const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');

// Get timezone offset in ISO format (+hh:mm or -hh:mm)
const getTimeZoneOffset = (date: Date) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
};

// Add a method to Date object to return ISO string with timezone
Date.prototype.toISOStringWithTimeZone = function() {
    return this.getFullYear() +
      '-' + pad(this.getMonth() + 1) +
      '-' + pad(this.getDate()) +
      'T' + pad(this.getHours()) +
      ':' + pad(this.getMinutes()) +
      ':' + pad(this.getSeconds()) +
      getTimeZoneOffset(this);
  };
